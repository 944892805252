import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import useApi from '../../hooks/useApi';


const Catering = () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/products/5?populate=*`;
    const { data, loading } = useApi(apiUrl);
    const [ hero, setHero ] = useState(null);

    useEffect(() => {
        if(data) {
            setHero(data);
        }
    }, [data])

    /* Hero section */
    const displayHero = () => {
        if (loading || hero === null) {
            return null;
        }

        const headings = hero.headings;

        return (
            <div className='bark-bg'>
                <Container className='py-md-5 py-3'>
                    <Row className='align-items-center text-center text-white'>
                        <Col lg={8} className="offset-lg-2">
                            {headings.h1 ? <h1 className='text-white'>{headings.h1}</h1> : null}
                            {headings.h2 ? <h2 className='text-white'>{headings.h2}</h2> : null}
                            {headings.h3 ? <h3 className='text-white'>{headings.h3}</h3> : null}
                            {headings.description ? <p>{headings.description}</p> : null}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
    
    /* Items section */
    const displayItems = () => {
        return (
            <div>
                <Container className='py-md-5 py-3'>
                    <Row>
                        <gspro-item-list size="10000" tags="catering"></gspro-item-list>
                    </Row>
                </Container>
            </div>
        )
    }

    return (
        <div>
            {displayHero()}
            {displayItems()}
        </div>
    )
}


export { Catering };