import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

const TermsOfService = () => {
    return (
        <Container>
            <Row>
                <Col md={10} className="offset-md-1">
                    <h1>Terms of Service</h1>
            <strong>Effective Date: March 4th, 2024</strong><br/><br/>
            <p>Welcome to Honest Event Rentals LLC ("Honest Event Rentals," "we," "us," or "our"). By accessing or using our website, including any subdomains thereof (the "Site"), you agree to comply with and be bound by these Terms of Service ("Terms"). These Terms govern your access to and use of the Site and any services provided through the Site (collectively, the "Services"). Please read these Terms carefully before using the Site. If you do not agree to these Terms, you may not access or use the Site or Services.</p>
            <h2 className="mb-3">Acceptance of Terms</h2>
            <p>By accessing or using the Site, you agree to be bound by these Terms. If you are using the Site on behalf of an organization, you are agreeing to these Terms for that organization and warranting that you have the authority to bind that organization to these Terms. In that case, "you" and "your" will refer to that organization.</p>
            <h2 className="mb-3">Use of the Site</h2>
            <p>You may use the Site and Services only if you can form a binding contract with Honest Event Rentals and are not barred under any applicable laws from doing so. You may use the Site and Services only in compliance with these Terms and all applicable local, state, national, and international laws, rules, and regulations.</p>
            <h2 className="mb-3">Privacy Policy</h2>
            <p>Please review our Privacy Policy, which governs the use of personal information on the Site and to which you agree to be bound as a condition of using the Site.</p>
            <h2 className="mb-3">Intellectual Property</h2>
            <p>All content on the Site, including but not limited to text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Honest Event Rentals or its content suppliers and is protected by United States and international copyright laws. The compilation of all content on the Site is the exclusive property of Honest Event Rentals and is protected by United States and international copyright laws. All software used on the Site is the property of Honest Event Rentals or its software suppliers and is protected by United States and international copyright laws.</p>
            <h2 className="mb-3">Limitation of Liability</h2>
            <p>To the fullest extent permitted by applicable law, Honest Event Rentals and its affiliates, officers, employees, agents, partners, and licensors will not be liable to you or any third party for any indirect, incidental, special, consequential, or punitive damages (including without limitation damages for loss of profits, goodwill, or any other intangible loss) arising out of or relating to your access to or use of, or your inability to access or use, the Site or Services, whether based on warranty, contract, tort (including negligence), statute, or any other legal theory, whether or not Honest Event Rentals has been informed of the possibility of such damage.</p>
            <h2 className="mb-3">Changes to Terms</h2>
            <p>Honest Event Rentals reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
            <h2 className="mb-3">Governing Law</h2>
            <p>These Terms shall be governed by and construed in accordance with the laws of the State of Massachusetts, without regard to its conflict of law provisions.</p>
            <h2 className="mb-3">Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at <a href="mailto:contact@honesteventrentals.com">contact@honesteventrentals.com</a>.</p>
            <p>By using the Site or Services, you agree to these Terms and to our Privacy Policy. If you do not agree to these Terms or our Privacy Policy, you may not use the Site or Services.</p>
                </Col>
            </Row>
        </Container>
    );
}

export default TermsOfService;
