const handleResponse = (data) => {
    let images = [];
    let headings = {};
    let list = [];
    let button = "";
    let shortText = "";
    let textMedia = [];
    let collectionItems = [];
    let annotationsText = "";

    data.map((section, index) => {
        if (section.__component === 'section.image') {
            // Extract images
            const imagesData = section.image.data;

            imagesData.forEach((image, imageIndex) => {
                const imageUrl = image.attributes.url;
                images.push(imageUrl);
            });
        } else if (section.__component === 'section.headings') {
            // Extract headings
            const { h1, h2, h3, description } = section;
            headings["h1"] = h1;
            headings["h2"] = h2;
            headings["h3"] = h3;
            headings["description"] = description;

        } else if (section.__component === 'section.list') {
            // Extract list items
            const items = section.item;
            items.forEach((listItem) => {
                const item = {
                    "title": listItem.title,
                    "description": listItem.description,
                };
                list.push(item);
                // No need for return statement here since it's not creating a new array
            });
        } else if (section.__component === 'section.button') {
            // Extract button text
            button = section.text;
        } else if (section.__component === 'section.short-text') {
            // Extract short-text 
            shortText = section.text;
        } else if (section.__component === 'section.text-media') {
            // Handle text-media section
            const items = section.item;

            items.forEach((item) => {
                const mediaUrl = item.media.data[0].attributes.url;
                const text = item.text;
                textMedia.push({
                    "mediaUrl": mediaUrl,
                    "text": text
                });
                
            });
        } else if (section.__component === 'catalog.collection-item') {
            // Handle collection item for catalog
            collectionItems.push({
                "preheading": section.preheading.text,
                "headings": section.heading,
                "buttons": section.buttons,
                "image": section.image.data.attributes.url,
            });
        } else if (section.__component === 'section.text') {
            // Handles text for annotation (specifically for homepage)
            annotationsText = section.text;
        }else {
            return null;
        }

        return null;
    })

    return {images, headings, list, button, shortText, textMedia, collectionItems, annotationsText}
}

export default handleResponse;