import React, { useEffect,useState } from 'react';
import { Button, Col, Container, Dropdown, Image, Row } from 'react-bootstrap';
import useApi from '../hooks/useApi';
import handleResponse from '../hooks/handleResponse';
import '../styles/Catalog.css';


const RentalCatalog = () => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/catalog?populate[hero]=*&populate[collections][on][section.headings][populate]=*&populate[collections][on][catalog.collection-item][populate]=*&populate[categories][on][section.headings][populate]=*&populate[categories][on][section.list][populate]=*`;
  const { data, loading } = useApi(apiUrl);
  const [ hero, setHero ] = useState(null);
  const [ collections, setCollections ] = useState(null);
  const [ categories, setCategories ] = useState(null);

  useEffect(() => {
    if(data) {
        setHero(data.hero);
        setCollections(data.collections);
        setCategories(data.categories);
    }
  }, [data])

  /* Hero section */
  const displayHero = () => {
    if (loading || hero === null) {
      return null;
    }
    const {shortText, headings} = handleResponse(hero);
    return (
      <div className='bark-bg'>
        <Container className='py-md-4 py-3'>
          <Row className='align-items-center text-center text-white'>
            <Col lg={8} className="offset-lg-2">
              {shortText ? <p className='text-white'>{shortText.toUpperCase()}</p> : null}
              {headings.h1 ? <h1 className='text-white'>{headings.h1}</h1> : null}
              {headings.h2 ? <h2 className='text-white'>{headings.h2}</h2> : null}
              {headings.h3 ? <h3 className='text-white'>{headings.h3}</h3> : null}
              <Col md={8} className="offset-md-2">
                {headings.description ? <p style={{fontWeight:300}}>{headings.description}</p> : null}
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  /* Collections section */
  const displayCollections = () => {
    if (loading || collections === null) {
      return null;
    }
    const { headings, collectionItems } = handleResponse(collections);

    return (
      <div className='beige-bg'>
        <Container className='py-md-4 py-3'>
            <div className='mb-3'>
              {headings.h1 ? <h1>{headings.h1}</h1> : null}
              {headings.h2 ? <h2>{headings.h2}</h2> : null}
              {headings.h3 ? <h3>{headings.h3}</h3> : null}
              {headings.description ? <p>{headings.description}</p> : null}
            </div>
             
            <Row>
              {collectionItems.map((item) => (
                collectionItems.indexOf(item) === 0 ? 
                  <Col md={5} key={item.id} className="mb-md-0 mb-2">
                    {item.image ? <Image loading="lazy" type="image/webp" src={item.image} className="mb-2 collections-img" /> : null}
                    {item.preheading ? <p className='nav-text forest-text mt-2'>{item.preheading.toUpperCase()}</p> : null}
                    {item.headings.h1 ? <h1>{item.headings.h1}</h1> : null}
                    {item.headings.h2 ? <h2>{item.headings.h2}</h2> : null}
                    {item.headings.h3 ? <h3 className='h4 text-bark'>{item.headings.h3}</h3> : null}
                    {item.headings.description ? <p>{item.headings.description}</p> : null}
                    {item.buttons.map((buttonItem) => (
                      <div className='d-inline-flex flex-wrap' key={buttonItem.id}>
                          <Button
                              variant=""
                              className="text-nav btn-outline-secondary mx-1 my-md-2 my-1"
                              href={`/products/${buttonItem.text.split(" ").join("-").toLowerCase()}/`}>
                            {buttonItem.text}
                          </Button>
                      </div>
                    ))}
                  </Col>
                :

                <Col md={5} key={item.id} className="offset-md-1 mb-md-0 mb-2">
                    {item.image ? <Image loading="lazy" type="image/webp" src={item.image} className="mb-2 collections-img" /> : null}
                    {item.preheading ? <p className='nav-text forest-text mt-2'>{item.preheading.toUpperCase()}</p> : null}
                    {item.headings.h1 ? <h1>{item.headings.h1}</h1> : null}
                    {item.headings.h2 ? <h2>{item.headings.h2}</h2> : null}
                    {item.headings.h3 ? <h3 className='h4 text-bark'>{item.headings.h3}</h3> : null}
                    {item.headings.description ? <p>{item.headings.description}</p> : null}
                    {item.buttons.map((buttonItem) => (
                      <div className='d-inline-flex flex-wrap' key={buttonItem.id}>
                          <Button
                              variant=""
                              className="text-nav btn-outline-secondary mx-1 my-md-2 my-1"
                              href={`/products/${buttonItem.text.split(" ").join("-").toLowerCase()}/`}>
                            {buttonItem.text}
                          </Button>
                      </div>
                    ))}
                  </Col>
                
              ))}

            </Row>
        </Container>
      </div>
    );
  };

  /* Categories section */
  const DisplayCategories = () => {
  const [filteredInventory, setFilteredInventory] = useState(null);

  if (loading || categories === null) {
    return null;
  }

  const { headings, list } = handleResponse(categories);

  return (
    <div className="mint-bg">
      {/* eslint-disable-next-line no-unused-vars */}
      <Container>
        <Row className="align-items-center text-center py-md-4 py-3">
          {headings.h1 && <h1 className="forest-text">{headings.h1}</h1>}
          {headings.h2 && <h2 className="forest-text">{headings.h2}</h2>}
          {headings.h3 && <h3 className="forest-text">{headings.h3}</h3>}
          {headings.description && <p className="forest-text">{headings.description}</p>}
          <Col lg={10} className="offset-lg-1 my-md-4 mb-3 catalog-text">
            <div id="inventory" className="d-md-flex flex-wrap justify-content-between d-none">
              <Button onClick={() => setFilteredInventory(null)}>
                {filteredInventory === null ? <strong>ALL PRODUCTS</strong> : "ALL PRODUCTS"}
              </Button>
              {list.map((link) => (
                <Button
                  key={link.title}
                  onClick={() => setFilteredInventory(link.title)}
                  className={filteredInventory === link.title ? 'active' : ''}
                >
                  {link.title.toUpperCase()}
                </Button>
              ))}
            </div>
            <Dropdown className="d-block d-md-none">
              <Dropdown.Toggle variant="secondar-outline border-0 text-color" id="dropdown-basic">
                {filteredInventory ? filteredInventory.toUpperCase() : 'All Products'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setFilteredInventory(null)}>All Products</Dropdown.Item>
                {list.map((link) => (
                  <Dropdown.Item
                    key={link.title}
                    onClick={() => setFilteredInventory(link.title)}
                    className={filteredInventory === link.title ? 'active' : ''}
                  >
                    {link.title.toUpperCase()}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <div className="inventory">
            {filteredInventory ? 
              <gspro-item-list size="10000" tags={filteredInventory}></gspro-item-list> : 
              <gspro-item-list size="10000" search="*"></gspro-item-list>
            }
          </div>
        </Row>
      </Container>
    </div>
  );
};

  return (
    <div>
      {displayHero()}
      {displayCollections()}
      {DisplayCategories()}
    </div>
  );
}

export { RentalCatalog };
