import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import handleResponse from '../../hooks/handleResponse';

const LazyHero = ({ data, loading }) => {
  if (loading || !data) {
    return null;
  }

  const { images, headings, button, shortText } = handleResponse(data.hero);

  return (
    <div className='beige-bg py-md-5'>
      <Container>
        <Row className='align-items-center'>
          <Col md={5} className="order-1 order-md-0 text-center d-flex position-relative justify-content-center limit-height">
              <div className=''>
                <Image src={images[0]} type="image/webp" width="313" height="409" className="hero-image arched-image" />
              </div>
            </Col>
            <Col md={6} className="order-0 order-md-1">
              {shortText ? <p>{shortText.toUpperCase()}</p> : null}
              {headings.h1 ? <h1>{headings.h1}</h1> : null}
              {headings.h2 ? <h2>{headings.h2}</h2> : null}
              {headings.h3 ? <h3>{headings.h3}</h3> : null}
              {headings.description ? <p>{headings.description}</p> : null}
              {button ? <Button variant="" className="cta" href='/products/'>{button.toUpperCase()}</Button> : null}
            </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LazyHero;
