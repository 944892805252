import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import handleResponse from '../../hooks/handleResponse';

const LazyAnnotations = ({ data, loading }) => {
  if (loading || !data) {
    return null;
  }

  const { annotationsText } = handleResponse(data.annotations);

  return (
    <div>
      <Container className='my-3 pt-3'>
        <Row className='align-items-center'>
          <Col>
              <ol>
                <li>
                  <small style={{ maxWidth: '100%', overflowWrap: 'break-word' }}><a href={annotationsText} target="_blank" rel="noreferrer">The Secret Waste That Weddings Leave Behind</a></small>
                </li>
              </ol>
            </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LazyAnnotations;
