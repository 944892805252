import React from 'react';
import { Accordion } from 'react-bootstrap';

const FAQAccordion = ({ faqs }) => {
  return (
    <Accordion defaultActiveKey="0">
      {faqs.map((category, index) => (
        <React.Fragment key={index}>
          <div className="mb-4">
            <h2>{category.heading}</h2>
            {category.items.map((faq, faqIndex) => (
              <Accordion.Item key={faqIndex} eventKey={index.toString() + faqIndex.toString()}>
                <Accordion.Header>{faq.question}</Accordion.Header>
                <Accordion.Body>{faq.answer}</Accordion.Body>
              </Accordion.Item>
            ))}
          </div>
        </React.Fragment>
      ))}
    </Accordion>
  );
};


export default FAQAccordion;
