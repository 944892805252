import React from 'react';
import { Carousel, Col, Container, Image, Row } from 'react-bootstrap';
import handleResponse from '../../hooks/handleResponse';

import { ChevronRight } from 'react-bootstrap-icons';


const LazyProducts = ({ data, loading }) => {
  if (loading || !data) {
    return null;
  }

  const { headings, shortText, textMedia } = handleResponse(data.products);

  return (
    <div className='bark-bg'>
      <Container className='py-md-5 py-3'>
        <Row className='text-center justify-content-center'>
          <Col md={10}>
              <Col lg={8} md={10} className="offset-lg-2 offset-lg-2 offset-md-1">
                {shortText ? <p className='text-white'>{shortText.toUpperCase()}</p> : null}
                {headings.h1 ? <h1 className='text-white'>{headings.h1}</h1> : null}
                {headings.h2 ? <h2 className='text-white'>{headings.h2}</h2> : null}
                {headings.h3 ? <h3 className='text-white'>{headings.h3}</h3> : null}
                {headings.description ? <p className='text-white'>{headings.description}</p> : null}
              </Col>
              <Row className="d-none d-md-flex flex-md-row">
                {textMedia.map((textMediaItem) => (
                  <Col md={4} sm={6}>
                    <a href={`/products/${textMediaItem.text.toLowerCase()}/`} rel="noopener noreferrer" className='carousel'>
                      <div className="p-2 bg-white my-3 mx-2 rounded-4">
                        <Image
                          className="grid-image"
                          max-width="280"
                          max-height="300"
                          type="image/webp"
                          loading="lazy"
                          src={textMediaItem.mediaUrl}
                        />
                        <div className='d-flex justify-content-end align-items-center mt-2'>
                          <span className='text-end mb-0'><strong>{textMediaItem.text.toUpperCase()}</strong></span>
                          &nbsp;
                          <ChevronRight />
                        </div>
                      </div>
                    </a>
                  </Col>
                ))}
              </Row>
              {/* Display carousel on mobile only */}
              <Row>
                <Col md={12} className="d-md-none d-inline">
                    <div className="d-flex flex-column">
                        <Carousel interval={null} pause={true}>
                          {textMedia.map((textMediaItem) => (
                              <Carousel.Item>
                                <a href={`/products/${textMediaItem.text.toLowerCase()}/`} rel="noopener noreferrer" className='carousel'>
                                    <div className="p-4 bg-white mb-4">
                                      <Image
                          className="grid-image"
                          max-width="280"
                          max-height="300"
                          type="image/webp"
                          loading="lazy"
                          src={textMediaItem.mediaUrl}
                        />
                                      <div className='d-flex justify-content-end align-items-center mt-2'>
                                        <span className='text-end mb-0'><strong>{textMediaItem.text.toUpperCase()}</strong></span>
                                        &nbsp;
                                        <ChevronRight />
                                      </div>
                                    </div>
                                </a>
                              </Carousel.Item>
                          ))}
                        </Carousel>
                    </div>
                </Col>
              </Row>
            </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LazyProducts;
