import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CanonicalLink = () => {
  const location = useLocation();

  useEffect(() => {
    const canonicalUrl = determineCanonicalUrl(location.pathname);
    updateCanonicalLink(canonicalUrl);
  }, [location.pathname]);

  const determineCanonicalUrl = (pathname) => {
        const pathWithoutHashAndQuery = pathname.split(/[?#]/)[0];
        const canonicalUrl = pathWithoutHashAndQuery.toLowerCase();
        return canonicalUrl;
    };


  const updateCanonicalLink = (canonicalUrl) => {
    let link = document.querySelector('link[rel="canonical"]');
    if (!link) {
      link = document.createElement('link');
      link.rel = 'canonical';
      document.head.appendChild(link);
    }
    link.href = canonicalUrl;
  };

  return null;
};

export default CanonicalLink;
