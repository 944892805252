import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import handleResponse from '../../hooks/handleResponse';

const LazyStandards = ({ data, loading }) => {
  if (loading || !data) {
    return null;
  }

  const { images, headings, button, shortText } = handleResponse(data.standards);

  return (
    <Container className='mt-md-3 py-5'>
      <Row className='align-items-center mt-3'>
        <Col md={5} className="offset-md-1">
            {shortText ? <p>{shortText.toUpperCase()}</p> : null}
            {headings.h1 ? <h1>{headings.h1}</h1> : null}
            {headings.h2 ? <h2>{headings.h2.slice(0, -1)}<sup style={{fontSize: "30%", position: "relative", top:"-0.7rem"}}>{headings.h2.slice(-1)}</sup></h2>: null}
            {headings.h3 ? <h3>{headings.h3}</h3> : null}
            {headings.description ? <p>{headings.description}</p> : null}
            {button ? <Button variant="" className="cta" href="/our-values/">{button.toUpperCase()}</Button> : null}
        </Col>
        <Col md={5} className="d-flex justify-content-md-start justify-content-center">
            <Image src={images[0]} height="360" width="360" type="image/webp" loading="lazy" />
        </Col>
      </Row>
    </Container>
  );
};

export default LazyStandards;
