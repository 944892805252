import { Container, Button } from "react-bootstrap";

const ComingSoon = () => {
    return (
        <Container>
            <div className="d-flex flex-column align-items-center text-center my-5">
                <h1>COMING SOON</h1>
                <Button variant="" className="cta" href='/products/'>View Rental Catalog</Button>
            </div>
        </Container>
    )
}

export { ComingSoon };