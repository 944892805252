import React, { useEffect } from 'react';
import { useState } from 'react';
import useApi from '../hooks/useApi';
import { Button, Col, Container, Row, Image } from 'react-bootstrap';

import handleResponse from '../hooks/handleResponse';
import '../styles/Homepage.css';
import '../styles/Standards.css';

/* To Do: Rename Standards to values */
const OurStandards = () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/our-standard?populate[hero][populate]=*&populate[ecoConscious][populate]=*&populate[locallySupported][populate]=*&populate[repurposed][populate]=*&populate[callout][populate]=*&populate[artisan][populate]=*`;
    const { data, loading } = useApi(apiUrl);
    const [ hero, setHero ] = useState(null);
    const [ ecoConscious, setEcoConscious ] = useState(null);
    const [ local, setLocal ] = useState(null);
    const [ repurposed, setRepurposed ] = useState(null);
    const [ artisan, setArtisan ] = useState(null);
    const [ callout, setCallout ] = useState(null);

    useEffect(() => {
        if(data) {
            setHero(data.hero);
            setEcoConscious(data.ecoConscious);
            setLocal(data.locallySupported);
            setRepurposed(data.repurposed);
            setArtisan(data.artisan);
            setCallout(data.callout);
        }
    }, [data])

    /* Hero section */
    const displayHero = () => {
        if (loading || hero === null) {
            return null;
        }
        const {headings, button, shortText} = handleResponse(hero);
        return (
            <div className='bark-bg'>
                <Container className='py-4'>
                    <Row className='text-center text-white py-lg-3'>
                        <Col lg={8} className="d-flex flex-wrap align-items-center justify-content-center offset-lg-2">
                            {shortText ? <p>{shortText.toUpperCase()}</p> : null}
                            {headings.h1 ? <h1 className='text-white'>{headings.h1}</h1> : null}
                            {headings.h2 ? <h2 className='text-white'>{headings.h2}</h2> : null}
                            {headings.h3 ? <h3 className='text-white'>{headings.h3}</h3> : null}
                            <Col md={8}>
                                {headings.description ? <p>{headings.description}</p> : null}
                            </Col>
                            {button ? <Button variant="" className="cta">{button.toUpperCase()}</Button> : null}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    /* EcoConscious section */
    const displayEcoConscious = () => {
        if (loading || ecoConscious === null) {
            return null;
        }
        const {images, headings, button, shortText} = handleResponse(ecoConscious);
        return (
            <Container>
                <Row className='align-items-center my-md-5 my-3'>
                    <Col lg={4} md={5} className="offset-md-1">
                        <Image type="image/webp" src={images[0]} className="mb-2 icon-img" loading="lazy" />
                        {shortText ? <p>{shortText.toUpperCase()}</p> : null}
                        {headings.h1 ? <h1>{headings.h1}</h1> : null}
                        {headings.h2 ? <h2>{headings.h2}</h2> : null}
                        {headings.h3 ? <h3>{headings.h3}</h3> : null}
                        {headings.description ? <p>{headings.description}</p> : null}
                        {button ? <Button variant="" href='/products/eco-consciously-made/' className="cta mb-md-0 mb-4">{button.toUpperCase()}</Button> : null}
                    </Col>
                    <Col md={5} className="d-flex justify-content-center justify-content-md-start p-md-0 offset-lg-1">
                        <Image type="image/webp" loading="lazy" className="standard-img" src={images[1]} />
                    </Col>
                </Row>
            </Container>
        )
    }

    /* Locally Made section */
    const displayLocal = () => {
        if (loading || local === null) {
            return null;
        }
        const {images, headings, button, shortText} = handleResponse(local);
        return (
            <div className='beige-bg'>
                <Container>
                    <Row className='align-items-center py-md-5 py-3'>
                        <Col md={5} lg={4} className="order-md-0 order-1 d-flex justify-content-center justify-content-md-start p-md-0 offset-md-1">
                            <Image loading="lazy" type="image/webp" src= {images[1]} className="standard-img" />
                        </Col>
                        <Col md={5} className="order-md-1 order-0 offset-lg-1">
                            <Image loading="lazy" src={images[0]} className="mb-2 icon-img" />
                            {shortText ? <p>{shortText.toUpperCase()}</p> : null}
                            {headings.h1 ? <h1 className='forest-text'>{headings.h1}</h1> : null}
                            {headings.h2 ? <h2 className='forest-text'>{headings.h2}</h2> : null}
                            {headings.h3 ? <h3 className='forest-text'>{headings.h3}</h3> : null}
                            {headings.description ? <p>{headings.description}</p> : null}
                            {button ? <Button variant="" className="cta mb-md-0 mb-4" href="/products/locally-supported/">{button.toUpperCase()}</Button> : null}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    /* Repurposed section */
    const displayRepurposed = () => {
        if (loading || repurposed === null) {
            return null;
        }
        const {images, headings, button, shortText} = handleResponse(repurposed);
        return (
            <Container>
                <Row className='align-items-center my-md-5 my-3'>
                    <Col md={5} lg={4} className="offset-md-1">
                        <Image loading="lazy" type="image/webp" src={images[0]} className="mb-2 icon-img" />
                        {shortText ? <p>{shortText.toUpperCase()}</p> : null}
                        {headings.h1 ? <h1>{headings.h1}</h1> : null}
                        {headings.h2 ? <h2>{headings.h2}</h2> : null}
                        {headings.h3 ? <h3>{headings.h3}</h3> : null}
                        {headings.description ? <p>{headings.description}</p> : null}
                        {button ? <Button variant="" className="cta mb-md-0 mb-4" href="/products/thoughtfully-repurposed/">{button.toUpperCase()}</Button> : null}
                    </Col>
                    <Col md={5} className="d-flex justify-content-center justify-content-md-start p-md-0 offset-lg-1">
                        <Image loading="lazy" type="image/webp" src={images[1]} className="standard-img" />
                    </Col>
                </Row>
            </Container>
        )
    }

    /* Artisan Made section */
    const displayArtisanMade = () => {
        if (loading || artisan === null) {
            return null;
        }
        const {images, headings, button, shortText} = handleResponse(artisan);
        return (
            <div className='beige-bg'>
                <Container>
                    <Row className='align-items-center py-md-5 py-3'>
                        <Col md={5} lg={4} className="order-md-0 order-1 d-flex justify-content-center justify-content-md-start p-md-0 offset-md-1">
                            <Image loading="lazy" type="image/webp" src= {images[1]} className="standard-img" />
                        </Col>
                        <Col md={5} className="order-md-1 order-0 offset-lg-1">
                            <Image loading="lazy" type="image/webp" src={images[0]} className="mb-2 icon-img" />
                            {shortText ? <p>{shortText.toUpperCase()}</p> : null}
                            {headings.h1 ? <h1 className='forest-text'>{headings.h1}</h1> : null}
                            {headings.h2 ? <h2 className='forest-text'>{headings.h2}</h2> : null}
                            {headings.h3 ? <h3 className='forest-text'>{headings.h3}</h3> : null}
                            {headings.description ? <p>{headings.description}</p> : null}
                            {button ? <Button variant="" className="cta mb-md-0 mb-4" href="/products/artisan-crafted/">{button.toUpperCase()}</Button> : null}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    /* Callout section */
    const displayCallout = () => {
        if (loading || callout === null) {
            return null;
        }
        const {headings, button, shortText} = handleResponse(callout);
        return (
            <div className='mint-bg'>
                <Container className='py-5'>
                    <Row className='d-flex flex-wrap justify-content-center text-center'>
                        <Col lg={6} md={10}>
                            {shortText ? <p>{shortText.toUpperCase()}</p> : null}
                            {headings.h1 ? <h1>{headings.h1}</h1> : null}
                            {headings.h2 ? <h2>{headings.h2}</h2> : null}
                            {headings.h3 ? <h3>{headings.h3}</h3> : null}
                            
                        </Col>
                        <Row className='d-flex flex-wrap justify-content-center text-center'>
                            <Col lg={5}>
                                {headings.description ? <p>{headings.description}</p> : null}
                                {button ? <Button variant="" className="cta mb-0" href="/products/">{button.toUpperCase()}</Button>: null}
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </div>
        )
    }

    return (
        <div>
        {loading ? (
            <p>Loading...</p>
        ) : (
            <div>
            {displayHero()}
            {displayEcoConscious()}
            {displayLocal()}
            {displayRepurposed()}
            {displayArtisanMade()}
            {displayCallout()}
            </div>
        )}
        </div>
  );
}

export {OurStandards};