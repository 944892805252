import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

const PrivacyPolicy = () => {
    return (
        <Container>
            <Row>
                <Col md={10} className="offset-md-1">
                    <h1>Privacy Policy</h1>
                        <strong>Last Updated: March 4, 2024</strong><br/><br/>
                        <h2>Introduction</h2>
                        <p>Honest Event Rentals LLC ("Honest Event Rentals," "we," "our," or "us") is committed to providing you with the highest quality online event services and planning experience. We are also committed to maintaining the following Privacy Policy to protect your personal information. Please carefully review this Policy and our [Terms and Conditions] before using our Website at honesteventrentals.com. By using this Website, you signify you are in agreement with both our Privacy Policy and our Terms and Conditions, subject to our “Changes to our Privacy Policy” section below.
                        </p>
                        <h2>Collection and Storage of Information</h2>
                        <p>We permit customers and visitors to submit various types of information to Honest Event Rentals via the Website, including but not limited to:
                        <ul>
                            <li>First and last name</li>
                            <li>Email address</li>
                            <li>Company name</li>
                            <li>Title</li>
                            <li>Address information</li>
                            <li>Phone number</li>
                            <li>Industry type</li>
                            <li>Information regarding interests</li>
                        </ul>
                        During a purchase transaction, we will collect and store your name, address, telephone number, email address, event information, as well as keeping records of your specific purchases and details of the transaction.
                        We may also collect information if you interact with us on social media platforms such as Facebook or Instagram. Additionally, we may use "cookies" and similar tools to recognize you and your access privileges on our site, as well as to track site usage.
                        </p>
                        <h2>Use of Information</h2>
                        <p>The information we collect is used for various purposes, including but not limited to:
                        <ul>
                            <li>Providing the services requested</li>
                            <li>Providing information about related services and/or products</li>
                            <li>Improving our website</li>
                            <li>Developing new services and/or products</li>
                            <li>Systems administration and troubleshooting purposes</li>
                        </ul>
                        We will not share your email address or other individually-submitted information with parties outside of Honest Event Rentals, except as described in this Privacy Policy.
                        </p>
                        <h2>Third-Party Links</h2>
                        <p>Occasionally, we may include or offer third-party products or services on the Honest Event Rental website, or link to services offered on such third-party websites. These third-party sites have separate and independent privacy policies. We have no responsibility or liability for the content and activities of these linked sites. If you have questions about how these third parties use and collect data, please carefully read their privacy policies.</p>
                        <h2>Retention of Information</h2>
                        <p>We retain individually collected information as long as necessary to fulfill the purposes provided in this Privacy Policy, unless required or permitted by applicable laws.</p>
                        <h2>Changes to our Privacy Policy</h2>
                        <p>We reserve the right to update our Privacy Policy as necessary. In the event of updates, we will indicate the effective date of the changes below. We encourage you to return to this page often to ensure your knowledge of the current Privacy Policy is up to date.
                        This policy was last modified on [March 4, 2024].</p>
                        <h2>Opt-Out Procedures</h2>
                        <p>If you have submitted information to HER and would like to have the information deleted and/or removed from active use by Honest Event Rentals, you may request removal by emailing contact@honesteventrentals.com at any time. Please allow up to 7 business days for the requested deletion/removal to take effect.</p>
                        <h2>Privacy Contact</h2>
                        <p>Questions regarding this Privacy Policy should be directed to Honest Event Rentals by emailing <a href="mailto:contact@honesteventrentals.com">contact@honesteventrentals.com</a>.</p>
                </Col>
            </Row>
        </Container>
    );
}

export default PrivacyPolicy;
