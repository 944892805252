import React, { useEffect,useState } from 'react';
import { Button, Col, Container, Row, Image } from 'react-bootstrap';
import useApi from '../hooks/useApi';
import handleResponse from '../hooks/handleResponse';


const WhoWeAre = () => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/who-we-are?populate[hero][populate]=*&populate[Mission][populate]=*&populate[WhatWeDo][populate]=*&populate[WhereWeService][populate]=*&populate[Callout][populate]=*`;
  const { data, loading } = useApi(apiUrl);
  const [ hero, setHero ] = useState(null);
  const [ mission, setMission ] = useState(null);
  const [ whatWeDo, setWhatWeDo ] = useState(null);
  const [ whereWeService, setWhereWeService ] = useState(null);
  const [ callout, setCallout ] = useState(null);

  useEffect(() => {
    if(data) {
        setHero(data.hero);
        setMission(data.Mission);
        setWhatWeDo(data.WhatWeDo);
        setWhereWeService(data.WhereWeService);
        setCallout(data.Callout);
    }
  }, [data])

  /* Hero section */
  const displayHero = () => {
    if (loading || hero === null) {
      return null;
    }
    const {images, headings, button, shortText} = handleResponse(hero);
    return (
      <div className='beige-bg py-md-5'>
        <Container className='py-md-0 pt-3'>
          <Row className='align-items-center'>
            <Col md={5} className="order-1 order-md-0 text-center d-flex position-relative justify-content-center limit-height">
              <div className='d-flex position-absolute align-items-center'>
                <Image loading="lazy" type="image/webp" src={images[0]} max-width="330" max-height="340" className="hero-image arched-image" />
              </div>
            </Col>
            <Col md={6} className="order-0 order-md-1">
              {shortText ? <p>{shortText.toUpperCase()}</p> : null}
              {headings.h1 ? <h1>{headings.h1}</h1> : null}
              {headings.h2 ? <h2>{headings.h2}</h2> : null}
              {headings.h3 ? <h3>{headings.h3}</h3> : null}
              {headings.description ? <p>{headings.description}</p> : null}
              {button ? <Button variant="" className="cta">{button.toUpperCase()}</Button> : null}
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  /* Mission section */
  const displayMission = () => {
    if (loading || mission === null) {
      return null;
    }
    const {headings, shortText} = handleResponse(mission);
    return (
      <Container className='my-md-5 pb-md-5 pt-3'>
        <Row className='align-items-center mt-5 py-lg-5'>
          <Col md={4} className="offset-md-1">
            {shortText ? <p>{shortText.toUpperCase()}</p> : null}
            {headings.h1 ? <h1>{headings.h1}</h1> : null}
            {headings.h2 ? <h2><span className='h3'>{headings.h2}</span></h2> : null}
            {headings.h3 ? <h3>{headings.h3}</h3> : null}
          </Col>
          <Col md={6}>
            {headings.description ? <p className='mb-0'>{headings.description}</p> : null}
          </Col>
        </Row>
      </Container>
    )
  }

  /* What We Do section */
  const displayWhatWeDo = () => {
    if (loading || whatWeDo === null) {
      return null;
    }
    const {images, headings, button, shortText} = handleResponse(whatWeDo);
    return(
      <div className='bark-bg py-5 mt-md-5 mt-5'>
        <Container fluid>
          <Row className='align-items-center'>
            <Col md={3} className="p-0 d-none d-md-block">
              <div className="d-flex flex-row justify-content-start">
                <Image loading="lazy" type="image/webp" src={images[0]} className="mw-380" />
              </div>
            </Col>

            <Col md={6} className="text-center">
              {shortText ? <p className='text-white'>{shortText.toUpperCase()}</p> : null}
              {headings.h1 ? <h1 className='text-white'>{headings.h1}</h1> : null}
              {headings.h2 ? <h2 className='text-white'>{headings.h2}</h2> : null}
              {headings.h3 ? <h3 className='text-white'>{headings.h3}</h3> : null}
              {headings.description ? <p className='text-white'>{headings.description}</p> : null}
              {button ? <Button variant="" className="cta mb-md-0 mb-4" href="/products/">{button.toUpperCase()}</Button> : null}
            </Col>

            <Col md={3} className="p-0 d-none d-md-block">
              <div className="d-flex flex-row justify-content-end">
                <Image loading="lazy" type="image/webp" src={images[1]} className="mw-380" />
              </div>
            </Col>
          </Row>
        </Container>
        <div className='d-flex d-md-none justify-content-center'>
          <Image loading="lazy" type="image/webp" src={images[0]} className="mw-280" />
          <Image loading="lazy" type="image/webp" src={images[1]} className="mw-280" />
        </div>

      </div>
    )
  }

  /* Where We Service section */
  const displayWhereWeService = () => {
    if (loading || whereWeService === null) {
      return null;
    }
    const {images, headings, button, shortText} = handleResponse(whereWeService);
    return (
      <Container className='py-md-5 py-5'>
        <Row className='align-items-center'>
          <Col md={5} className="offset-md-1">
            {shortText ? <p>{shortText.toUpperCase()}</p> : null}
            {headings.h1 ? <h1>{headings.h1}</h1> : null}
            {headings.h2 ? <h2>{headings.h2}</h2> : null}
            {headings.h3 ? <h3>{headings.h3}</h3> : null}
            {headings.description ? <p>{headings.description}</p> : null}
            {button ? <Button variant="" className="cta" href="/contact-us/">{button.toUpperCase()}</Button> : null}
          </Col>
          <Col md={5}>
            <Image loading="lazy" type="image/webp" className="mw-380" src={images[0]} />
          </Col>
        </Row>
      </Container>
    )
  }

  /* Callout section */
  const displayCallout = () => {
    if (loading || callout === null) {
      return null;
    }
    const {headings, button, shortText} = handleResponse(callout);
    return (
      <div className='mint-bg'>
        <Container className='py-5'>
          <Row className='text-center'>
            <Col md={10} className="text-center offset-md-1">
              {shortText ? <p>{shortText.toUpperCase()}</p> : null}
              {headings.h1 ? <h1>{headings.h1}</h1> : null}
              {headings.h2 ? <h2 className='forest-text'>{headings.h2}</h2> : null}
              {headings.h3 ? <h3>{headings.h3}</h3> : null}
              {headings.description ? <p>{headings.description}</p> : null}
              {button ? <Button variant="" className="cta mt-3" href="/products/">{button.toUpperCase()}</Button>: null}
            </Col>
        </Row>
        </Container>
      </div>
    )
  }
  
  return (
    <div>
      {displayHero()}
      {displayMission()}
      {displayWhatWeDo()}
      {displayWhereWeService()}
      {displayCallout()}
    </div>
  );
}

export { WhoWeAre };
