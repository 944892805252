import React from 'react';
import FAQAccordion from '../components/FAQAccordion';
import { Container, Col, Row } from 'react-bootstrap';
import '../styles/Faq.css';


const Faq = () => {
  const faqs = [
  {
    heading: 'Ordering and Reservations',
    items: [
      {
        question: 'How do I place an order with Honest Event Rentals?',
        answer: 'To place an order, simply add items to your wishlist on our website. We\'ll confirm availability and email you a quote covering rental and delivery/setup fees.'
      },
      {
        question: 'What is the reservation process?',
        answer: 'We cannot guarantee the availability of items until an order has been finalized by paying a deposit. Upon submitting your wishlist, we will review the availability of the items and provide you with a finalized quote. Your items will be officially reserved once we have received the required deposit and confirmed your order.'
      },
      {
        question: 'Do you have an order minimum?',
        answer: 'Yes, our order minimum is $300.'
      }
    ]
  },
  {
    heading: 'Product Care and Return',
    items: [
      {
        question: 'How should I return rental items?',
        answer: 'Please remove/wipe food from plates and liquid from drinkware before repacking. We appreciate your help in keeping our items clean and ready for the next event.'
      },
      {
        question: 'I accidentally broke items during my event. Is that covered by the Protection Plan?',
        answer: 'Yes, the Protection Plan covers minor damage due to accidental breakage during normal item use, such as chips or cracks. However, it does not cover damage from neglect, misuse, or theft. Please return broken pieces in a cardboard box for coverage.'
      }
    ]
  },
  {
    heading: 'Cancellation and Refunds',
    items: [
      {
        question: 'What is the cancellation policy?',
        answer: 'Cancellations made 14 days or more prior to the Delivery Date will incur charges equal to half of the rental fee. Cancellations made within 14 days of the Delivery Date will incur charges equal to 75% of the rental fee. Cancellations within 48 hours of the Delivery Date will incur charges equal to the full rental fee. Additional charges may apply if the order has been loaded and/or is en route.'
      }
    ]
  },
  {
    heading: 'Delivery',
    items: [
      {
        question: 'Where do you offer delivery services?',
        answer: 'We proudly serve clients in the Greater Boston area, North Boston, Bristol County, Plymouth County, Cape Cod, and Newport, Rhode Island.'
      },
      {
        question: 'Can I pick up rental items directly from your warehouse?',
        answer: 'At this time, we do not offer pickup services from our warehouse. All rental items are delivered directly to your designated location within our delivery area.'
      }
    ]
  },
  {
    heading: 'Our Values, Sustainability Efforts, and Inventory Quality',
    items: [
      {
        question: 'What are Honest Event Rentals\' values regarding sustainability?',
        answer: 'At Honest Event Rentals, we are committed to sustainability and eco-conscious practices. We carefully curate our inventory to include items that are locally sourced, eco-friendly, and support ethical production methods.'
      },
      {
        question: 'How do you ensure the quality of your inventory?',
        answer: 'We prioritize quality in our inventory selection process. Each item is thoroughly inspected and maintained to ensure it meets our standards before being rented out. We believe in providing our customers with high-quality rental items for their events.'
      },
      {
        question: 'How does Honest Event Rentals address plastic usage in its operations?',
        answer: 'While some items may currently be delivered in biodegradable plastic packaging or containers for logistical reasons, Honest Event Rentals is actively working to reduce overall plastic usage. We are committed to finding innovative solutions and exploring alternatives to traditional plastics as we continue to grow. Our goal is to minimize plastic waste and support a healthier planet for future generations.'
      }
    ]
  },
  {
    heading: 'General Inquiries',
    items: [
      {
        question: 'How can I contact Honest Event Rentals for assistance?',
        answer: 'You can reach us by filling out our contact form or email at contact@honesteventrentals.com. We\'ll get back to you as soon as possible to assist with any questions or concerns you may have.'
      }
    ]
  }
];


  return (
    <>
      <div className='beige-bg text-center mb-5'>
          <div className='p-4'>
              <h1>Frequently Asked Questions</h1>
          </div>
      </div>

      <Container>
        <Row>
          <Col md={10} className="offset-md-1">
            <FAQAccordion faqs={faqs} />
            <p className='text-center mt-5'><strong>For any additional questions, you may <a href="mailto:contact@honesteventrentals.com" target="_blank" rel="noreferrer">reach us at contact@honesteventrentals.com</a></strong></p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export {Faq};
