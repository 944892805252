import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navigation from './components/Navigation';
import { Homepage } from './pages/Homepage'
import { WhoWeAre } from './pages/WhoWeAre';
import { OurStandards } from './pages/OurStandards';
import Footer from './components/Footer';
import { ComingSoon } from './pages/ComingSoon';
import { RentalCatalog } from './pages/RentalCatalog';
import { Catering } from './pages/products/Catering';
import { Chargers } from './pages/products/Chargers';
import { Decor } from './pages/products/Decor';
import { Dinnerware } from './pages/products/Dinnerware';
import { Drinkware } from './pages/products/Drinkware';
import { Flatware } from './pages/products/Flatware';
import { Contact } from './pages/Contact';
import { ThoughtfullyRepurposed } from './pages/collections/ThoughtfullyRepurposed';
import { EcoconsciouslyMade } from './pages/collections/EcoconsciouslyMade';
import { ArtisanCrafted } from './pages/collections/ArtisanCrafted';
import { LocallySupported } from './pages/collections/LocallySupported';
import { NewEngland } from './pages/collections/NewEngland';
import { AmalfiCoast } from './pages/collections/AmalfiCoast';
import { Tulum } from './pages/collections/Tulum';
import { Faq } from './pages/Faq';
import TermsOfService from "./pages/TermsOfService";
import CookiePolicy from "./pages/CookiePolicy";
import PrivacyPolicy from './pages/PrivacyPolicy';
import CanonicalLink from './CannonicalLink'; 

import ReactGA from "react-ga4";

function GoogleAnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    // initialize Google Analytics
    ReactGA.initialize('G-N9RZCEHDMF', {
      gaOptions: {
        storage: 'none', // disable cookie storage by default
        cookieFlags: 'SameSite=None; Secure', // sets custom cookie flags
      },
    });

    // Tracks page views when route changes
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
}

// Adds good shuffle pro to Nav
function App() {
  
  return (
    <Router>
      <CanonicalLink />
      <GoogleAnalyticsTracker/>
      <Navigation />
      <Routes>
        <Route path="*" element={<ComingSoon />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/coming-soon/" element={<ComingSoon />} />
        <Route path="/who-we-are" element={<WhoWeAre />} />
        <Route path="/our-values" element={<OurStandards />} />
        <Route path="/products" element={<RentalCatalog />} />
        <Route path="/products/chargers/" element={<Chargers />} />
        <Route path="/products/catering/" element={<Catering />} />
        <Route path="/products/decor/" element={<Decor />} />
        <Route path="/products/dinnerware/" element={<Dinnerware />} />
        <Route path="/products/flatware/" element={<Flatware />} />
        <Route path="/products/drinkware/" element={<Drinkware />} />
        <Route path="/products/artisan-crafted/" element={<ArtisanCrafted />} />
        <Route path="/products/locally-supported/" element={<LocallySupported />} />
        <Route path="/products/thoughtfully-repurposed/" element={<ThoughtfullyRepurposed />} />
        <Route path="/products/eco-consciously-made/" element={<EcoconsciouslyMade />} />
        <Route path="/products/new-england/" element={<NewEngland />} />
        <Route path="/products/amalfi-coast/" element={<AmalfiCoast />} />
        <Route path="/products/tulum/" element={<Tulum />} />
        <Route path="/contact/" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/terms-of-service/" element={<TermsOfService />} />
        <Route path="/cookie-policy/" element={<CookiePolicy />} />
        <Route path="/privacy-policy/" element={<PrivacyPolicy />} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
