import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "../styles/Footer.css"
import { Facebook, Instagram } from 'react-bootstrap-icons';

const footer = () => {
    return (
        <Container className='my-5 footer w-100'>
            <hr />
            <Row className='d-flex flex-wrap nav-text'>
                <Col md={3} className="my-2">
                    <div className="d-flex flex-column">
                        <strong>Phone</strong>
                        <span><a target="_blank" href="tel:15083884348" rel="noreferrer">508-388-4348</a></span>

                        <br/>
                        
                        <strong>Email</strong>
                        <span><a target="_blank" href="mailto:contact@honesteventrentals.com" rel="noreferrer">contact@honesteventrentals.com</a></span>

                        <br className='d-none d-md-block' />

                        <div className='d-none d-md-block mt-4 flex-column'>
                            <div style={{ width: '240px', height: '50px' }}></div>
                            <img src='/her-logo-x2.svg' width="240" height="50" alt="Honest Event Rentals" className='mb-2'/>
                            <br/>
                            <small>© 2024 HONEST EVENT RENTALS, LLC <br/> All RIGHTS RESERVED</small>
                            <div className="d-flex flex-lg-row flex-column">
                                <a href="/privacy-policy">Privacy Policy</a> <span className='px-1'> | </span>
                                <a href="/terms-of-service">Terms of Service</a> <span className='px-1'> | </span>
                                <a href="/cookie-policy">Cookie Policy</a>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col md={3} className="my-2">
                    <Row>
                        <strong>About Honest Event Rentals</strong>
                        <Col className="d-flex flex-column my-2">
                            <a href="/who-we-are">Who we are </a>
                            <a href="/our-values">Our Values</a>
                            <a href="/contact/">Contact us</a>
                            <a href="/faq">FAQs</a>
                        </Col>
                    </Row>
                </Col>

                <Col md={4} className="d-flex flex-column my-2">
                    <strong>Product Categories</strong>
                    <Row>
                        <Col md={6} className="d-flex flex-column my-md-2 my-0">
                            <a href="/products/new-england/">New England</a>
                            <a href="/products/amalfi-coast/">Amalfi Coast</a>
                            <a href="/products/tulum/">Tulum</a>
                            <a href="/products/artisan-crafted/">Artisan Crafted</a>
                            <a href="/products/locally-supported/">Locally Supported</a>
                            <a href="/products/thoughtfully-repurposed/">Thoughtfully Repurposed</a>
                            <a href="/products/eco-consciously-made/">Eco-consciously Made</a>
                        </Col>

                        <Col md={6} className="d-flex flex-column my-2">
                            <a href="/products/chargers">Chargers</a>
                            <a href="/products/dinnerware">Dinnerware</a>
                            <a href="/products/drinkware">Drinkware</a>
                            <a href="/products/flatware">Flatware</a>
                            <a href="/products/catering">Catering</a>
                        </Col>
                    </Row>
                </Col>

                <Col md={2} className="d-flex flex-column my-2">
                    <strong>Follow us on social</strong><br/>
                    <div className='d-flex flex-wrap'>
                        <a href="https://www.facebook.com/honesteventrentals/" rel="noreferrer" target="_blank">
                            <Facebook size={20} alt="Facebook" />
                        </a>
                        &nbsp;
                        <a href="https://www.instagram.com/honesteventrentals/" rel="noreferrer" target="_blank">
                            <Instagram size={20} alt="Instagram" />
                        </a>
                    </div>

                </Col>
                <div className='d-flex flex-wrap d-block d-md-none span-2 mt-4'>
                    <img src='/her-logo-x2.png' width="240" height="50" alt="Honest Event Rentals" className='mb-2'/>
                    <small>© 2024 HONEST EVENT RENTALS, LLC <br/> All RIGHTS RESERVED</small>
                    <div className="d-flex flex-row">
                        <a href="/privacy-policy">Privacy Policy</a> <span className='px-1'> | </span>
                        <a href="/terms-of-service">Terms of Service</a> <span className='px-1'> | </span>
                        <a href="/cookie-policy">Cookie Policy</a>
                    </div>

                </div>
            </Row>

        </Container>
    )
}

export default footer;