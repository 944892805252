import React from 'react';
import { Container,Col,Row } from 'react-bootstrap';

const CookiePolicy = () => {
    return (
        <Container>
            <Row>
                <Col md={10} className="offset-md-1">
            <h1>Cookie Policy</h1>
            <p>This Cookie Policy explains how Honest Event Rentals LLC ("we", "us", or "our") uses cookies and similar technologies when you visit our website www.honesteventrentals.com (the "Website"). By using the Website, you agree to the use of cookies as described in this Cookie Policy.</p>
            <h2>What are Cookies?</h2>
            <p>Cookies are small pieces of data stored on your device (computer or mobile device) when you browse the internet. They are used to remember your preferences, improve your browsing experience, and help us understand how you interact with our Website.</p>
            <h2>How We Use Cookies</h2>
            <strong>We use cookies for the following purposes:</strong>
            <p>1. <strong>Essential Cookies</strong>: These cookies are necessary for the operation of our Website. They enable you to navigate our Website and use its features, such as accessing secure areas.</p>
            <p>2. <strong>Analytics Cookies</strong>: These cookies help us analyze how users interact with our Website, which pages are visited most frequently, and how users move around the Website. The information collected is used to improve the performance of our Website and enhance your experience.</p>
            <p>3. <strong>Functionality Cookies</strong>: These cookies allow our Website to remember choices you make (such as your username, language, or region) and provide enhanced, more personalized features.</p> 
            <p>4. <strong>Targeting/Advertising Cookies</strong>: These cookies are used to deliver advertisements relevant to your interests. They may be used by third-party advertising networks to track your browsing activity across different websites.</p>

            <h2>Your Choices Regarding Cookies</h2>
            <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. Please note that blocking or deleting cookies may affect the functionality of our Website and your user experience.
            </p>
            <h2>Third-Party Cookies</h2>
            <p>We may also allow third parties, such as service providers and advertising partners, to place cookies on your device through our Website to provide us with analytics, advertising, and other services. These third-party cookies are subject to the respective privacy policies of their providers.
            </p>
            <h2>Contact Us</h2>
            <p>If you have any questions about our Cookie Policy, please contact us at contact@honesteventrentals.com.</p>
            <h2>Updates to this Cookie Policy</h2>
            <p>We may update this Cookie Policy from time to time to reflect changes in our practices or applicable laws. We encourage you to review this page periodically for the latest information on our use of cookies.</p>
            <strong>This Cookie Policy was last updated on March 4, 2024.</strong>
            </Col>
            </Row>
        </Container>
    );
}

export default CookiePolicy;
