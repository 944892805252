import React, {useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Modal, FormControl, Button, Container, InputGroup } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import '../styles/gsp.css';
import '../styles/Navigation.css'

const Navigation = () => {
    const [showModal, setShowModal] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setVisible(prevScrollPos > currentScrollPos);
            setPrevScrollPos(currentScrollPos);

            if(currentScrollPos <= 25 || !currentScrollPos){
                setVisible(false)
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);

    const handleSearchClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
        
    return (
        <Navbar bg="white" expand="lg" className={`nav-text ${visible ? 'fixed-top' : 'hidden'}`}>
            <Container>
                <div className='d-flex justify-content-between align-items-center'>
                    <Navbar.Brand href="/">
                        <img src='/her-logo-x2.svg' width="240" height="50" alt="Honest Event Rentals logo"/>
                    </Navbar.Brand>
                    <div className='p-3 d-md-none d-block'>
                        <Search onClick={handleSearchClick}/>
                    </div>
                    <Navbar.Toggle />
                </div>
                <Navbar.Collapse className='justify-content-around'>
                    <Nav className="mr-auto">
                        <NavDropdown title="RENTAL CATALOG">
                            <NavDropdown.Item href="/products">All Products</NavDropdown.Item>
                            <NavDropdown title="Collections" drop='end' className='nav-link py-0 my-0'>
                                <NavDropdown.Item href="/products/new-england/">New England</NavDropdown.Item>
                                <NavDropdown.Item href="/products/amalfi-coast/">Amalfi Coast</NavDropdown.Item>
                                <NavDropdown.Item href="/products/tulum/">Tulum</NavDropdown.Item>
                                <NavDropdown.Item href="/products/artisan-crafted/">Artisan Crafted</NavDropdown.Item>
                                <NavDropdown.Item href="/products/locally-supported/">Locally Supported</NavDropdown.Item>
                                <NavDropdown.Item href="/products/thoughtfully-repurposed/">Thoughtfully Repurposed</NavDropdown.Item>
                                <NavDropdown.Item href="/products/eco-consciously-made/">Eco-consciously Made</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown.Item href="/products/chargers/">Chargers</NavDropdown.Item>
                            <NavDropdown.Item href="/products/dinnerware/">Dinnerware</NavDropdown.Item>
                            <NavDropdown.Item href="/products/drinkware/">Drinkware</NavDropdown.Item>
                            <NavDropdown.Item href="/products/flatware/">Flatware</NavDropdown.Item>
                            <NavDropdown.Item href="/products/catering/">Catering</NavDropdown.Item>
                            <NavDropdown.Item href="/products/decor/">Decor</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="ABOUT US" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/who-we-are">Who we are</NavDropdown.Item>
                            <NavDropdown.Item href="/our-values">Our Values</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="/contact/">CONTACT US</Nav.Link>
                        <Nav.Link href="/faq">FAQ</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <div className="d-inline-flex align-items-center justify-space-around d-none d-md-block">
                        <InputGroup onClick={handleSearchClick}>
                            <FormControl placeholder="Search" />
                            <Button variant="outline-secondary">
                                <Search />
                            </Button>
                        </InputGroup>
                    </div>

                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Search for Products</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <gspro-item-gallery show-filters="false"></gspro-item-gallery>
                            </Container>
                        </Modal.Body>
                    </Modal>
            </Container>
        </Navbar>
    );
};

export default Navigation;
