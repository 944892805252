import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import handleResponse from '../../hooks/handleResponse';

const LazyHowTo = ({ data, loading }) => {
  if (loading || !data) {
    return null;
  }
  let allImages = [];

  const { images, headings, list, button, shortText } = handleResponse(data.howtorent);

  return (
    <div className='mint-bg'>
      <Container className='mt-md-5 pt-3'>
        <Row className='align-items-center'>
          <Col md={6} className="order-md-1 order-0 pt-md-0 pt-4 how-to">
              {shortText ? <p>{shortText.toUpperCase()}</p> : null}
              {headings.h1 ? <h1>{headings.h1}</h1> : null}
              {headings.h2 ? <h2>{headings.h2}</h2> : null}
              {headings.h3 ? <h3>{headings.h3}</h3> : null}
              {headings.description ? <p>{headings.description}</p> : null}
              <br/>
              {
                <ol>
                  {list.map((listItem) => (
                      <li>
                          <strong>{listItem.title}</strong>
                          <br />
                          {listItem.description}
                          <br /><br />
                      </li>
                      
                  ))}
              </ol>
              }
              {button ? <Button variant="" className="cta" href="/products/">{button.toUpperCase()}</Button> : null}
            </Col>
            <Col md={6} className="order-md-0 order-1 mt-md-5">
              <div className='d-flex flex-md-column flex-row justify-content-center'>
                {images.forEach((imageUrl, imageIndex) => {
                    const imageComponent = (
                      <Image src={imageUrl} type="image/webp" loading="lazy" className={`layover-img m-md-0 p-1 mb-4 rounded-4 ${imageIndex === 0 ? 'base-img' : 'overlay-img'}`} />
                    )
                    allImages.push(imageComponent);
                })}
                {allImages}
              </div>
            </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LazyHowTo;
