import React, { Suspense } from 'react';
import useApi from '../hooks/useApi';
import LazyHero from './homepage/LazyHero';
import LazyStandards from './homepage/LazyStandards';
import LazyHowTo from './homepage/LazyHowTo';
import LazyProducts from './homepage/LazyProducts';
import LazyAnnotations from './homepage/LazyAnnotations';


const Homepage = () => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/homepage?populate[hero][populate]=*&populate[standards][populate]=*&populate[howtorent][on][section.list][populate]=*&populate[howtorent][on][section.headings][populate]=*&populate[howtorent][on][section.button][populate]=*&populate[howtorent][on][section.image][populate]=*&populate[products][on][section.headings][populate]=*&populate[products][on][section.text-media][populate][item][populate]=*&populate[annotations][on][section.text][populate][item][populate]=*`;
  const { data, loading } = useApi(apiUrl);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Suspense fallback={<p>Loading...</p>}>
          <LazyHero data={data} loading={loading} />
          <LazyStandards data={data} loading={loading} />
          <LazyHowTo data={data} loading={loading} />
          <LazyProducts data={data} loading={loading} />
          <LazyAnnotations data={data} loading={loading} />
        </Suspense>
      )}
    </div>
  );
};

export {Homepage};
