import React, { useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import '../styles/Contact.css';


const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    venueName: '',
    venueAddress: '',
    eventDate: '',
    guestCount: '',
    eventType: '',
    how: '',
    etc: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });


        if (response.ok) {
            // Handle successful form submission
            console.log('Form submitted successfully');
        } else {
            // Handle errors
            console.error('Failed to submit form');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
    }
  };

  return (
    <div>
        <div className='beige-bg text-center mb-5'>
            <div className='p-4'>
                <h1>Contact Us</h1>
                <p>Need to chat? Drop us a message using the form below, and we'll get back to you as soon as possible. </p>
            </div>
        </div>
        <Container>
            <Row>
                <Col md={6} className="text-center order-md-0 order-1">
                    <div className='d-flex flex-md-column my-md-0 mt-5'>
                        {/*TO DO: SWAP OUT PICS */}
                        <Image loading="lazy" type="image/webp" className="base-img layover-img m-md-0 p-1 mb-4" src="https://zewpenouqufuoprsofiz.supabase.co/storage/v1/object/public/strapi-uploads/files/ContactUs1_DESKTOP.jpg-a3ce9f2f7604337248144b9f7b295218.jpg" />
                        <Image loading="lazy" type="image/webp" className="overlay-img layover-img m-md-0 p-1 mb-4" src="https://zewpenouqufuoprsofiz.supabase.co/storage/v1/object/public/strapi-uploads/files/ContactUs2_DESKTOP.jpg-2b0aadab1b68841145722ce193a9abff.jpg" /> 
                    </div>
                    <div className='questions'>
                        <p>Questions?< br/> Email us directly at contact@honesteventrentals.com</p>
                    </div>
                </Col>

                <Col md={6} className="order-md-1 order-0">
                    <form onSubmit={handleSubmit}>
                        <input
                            placeholder="Name"
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            />
                        <hr/>
                        <input
                            placeholder='Email'
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            />
                        <hr/>
                        <input
                            placeholder='Phone Number'
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            />
                        <hr/>
                        <input
                            placeholder="Venue Name"
                            type="text"
                            name="venueName"
                            value={formData.venueName}
                            onChange={handleChange}
                            />
                        <hr/>
                        <input
                            placeholder='Event Venue Address'
                            type="text"
                            name="venueAddress"
                            value={formData.venueAddress}
                            onChange={handleChange}
                            />
                        <hr/>
                            <input
                            placeholder='When is your event?'
                            type="date"
                            name="eventDate"
                            value={formData.eventDate}
                            onChange={handleChange}
                            />
                        <hr/>
                        <input
                            placeholder='Estimated Guest Count'
                            type="number"
                            name="guestCount"
                            value={formData.guestCount}
                            onChange={handleChange}
                            />
                        <hr/>
                        <input
                            placeholder='Type of Event? (wedding, baby shower, etc.)'
                            type="text"
                            name="eventType"
                            value={formData.eventType}
                            onChange={handleChange}
                            />
                        <hr/>
                            <input
                            placeholder='How did you hear about us?'
                            type="text"
                            name="how"
                            value={formData.how}
                            onChange={handleChange}
                            />
                        <hr/>
                        <input
                            placeholder='Desired rentals, quantities, comments:'
                            type="text"
                            name="etc"
                            value={formData.etc}
                            onChange={handleChange}
                            />
                        <hr/>
                        <button type="submit" className="btn btn-outline-secondary">Send</button>
                    </form>
                </Col>
            </Row>
        </Container>
    </div>
  );
};

export {Contact};
